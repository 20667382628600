<template>
  <div id="subscription-form">
    <el-form
      ref="subscription-form"
      :model="data"
      :rules="rules"
      label-width="140px"
    >
      <el-form-item
        :label="subscriptionTypeLabel"
        prop="subscription_type"
        class="is-required"
      >
        <el-select
          v-model="data[subscriptionType]"
          :disabled="isEdit"
          @change="onSubscriptionSelect"
        >
          <el-option
            v-for="option in subscriptionTypeOptions"
            :key="option.id"
            :label="option.name"
            :value="option.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Free Trial">
        <div class="row">
          <el-form-item prop="trial_start_date">
            <el-date-picker
              v-model="data.trial_start_date"
              type="date"
              placeholder="Choose start"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <div class="line">
            -
          </div>
          <el-form-item prop="trial_end_date">
            <el-date-picker
              v-model="data.trial_end_date"
              :picker-options="getPickerOptions(data.trial_start_date)"
              type="date"
              placeholder="Choose end"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item :label="isEdit || showEndDate ? 'Billing Dates' : 'Start Date'">
        <div class="row">
          <el-form-item prop="start_date">
            <el-date-picker
              v-model="data.start_date"
              :picker-options="getPickerOptions(data.trial_end_date)"
              type="date"
              placeholder="Choose start"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <div v-if="isEdit || showEndDate" class="row">
            <div class="line">
              -
            </div>
            <el-form-item prop="end_date">
              <el-tooltip :disabled="!disableEndDate">
                <div slot="content">
                  This can't be edited because the end date is in the past.
                  If you'd like to reactivate this property,
                  please add a new subscription.
                </div>
                <el-date-picker
                  v-model="data.end_date"
                  :picker-options="getPickerOptions(data.start_date)"
                  type="date"
                  placeholder="Choose end"
                  value-format="yyyy-MM-dd"
                  :disabled="disableEndDate"
                />
              </el-tooltip>
            </el-form-item>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="Monthly Rate" required>
        <div class="row short">
          <el-form-item prop="rate">
            <el-tooltip :disabled="!rateDisabled">
              <div slot="content">
                Premium Full Service properties receive Price Updating at no extra cost
              </div>
              <el-input
                v-model="data.rate"
                placeholder="0.00"
                class="small-input"
                :disabled="rateDisabled"
              >
                <template slot="prepend">
                  $ {{ currencyCode }}
                </template>
              </el-input>
            </el-tooltip>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="Set Up Fee" required>
        <div class="row short">
          <el-form-item prop="setup_fee">
            <el-input
              v-model="data.setup_fee"
              placeholder="-"
              class="small-input"
            >
              <template slot="prepend">
                $ {{ currencyCode }}
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="Discount">
        <div class="row">
          <el-form-item prop="discount">
            <el-input
              v-model="data.discount"
              placeholder="0.00"
              class="small-input"
            >
              <template v-if="data.discount_type === '$'" :slot="'prepend'">
                {{ data.discount_type }} {{ currencyCode }}
              </template>
              <template v-else :slot="'append'">
                {{ data.discount_type }}
              </template>
            </el-input>
          </el-form-item>
          <el-select v-model="data.discount_type" class="col small-input">
            <el-option label="Dollars" value="$" />
            <el-option label="Percent" value="%" />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="Monthly Total">
        <div class="row">
          <el-form-item>
            <el-input
              :value="getTotalValue(data)"
              placeholder="0.00"
              class="small-input"
              disabled
            >
              <template slot="prepend">
                $ {{ currencyCode }}
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="Billing Frequency">
        <div class="row">
          <el-form-item>
            <el-select v-model="data.billing_frequency">
              <el-option
                v-for="option in billingFrequencyOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item
        label="Low Density"
        prop="low_density"
      >
        <el-switch v-model="data.low_density" />
      </el-form-item>
      <el-form-item
        v-if="isEdit || showEndDate"
        label="Reason For Cancel"
        prop="reason_for_cancel"
      >
        <el-select
          v-model="data.reason_for_cancel"
          filterable
          clearable
        >
          <el-option
            v-for="option in reasonForCancel"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="data.reason_for_cancel === 'other'"
        label="Please Specify"
        prop="reason_for_cancel_other"
      >
        <el-input v-model="data.reason_for_cancel_other" />
      </el-form-item>
      <el-form-item label="Notes">
        <el-input
          v-model="data.notes"
          type="textarea"
          :rows="4"
        />
      </el-form-item>

      <el-form-item
        v-if="isEdit"
        label="Recurring Invoice"
        prop="zoho_recurring_invoice"
      >
        <recurring-invoice-dropdown
          v-model="data.zoho_recurring_invoice"
        />
      </el-form-item>

      <div class="form-controls">
        <el-button
          id="cancel-btn"
          @click="cancel"
        >
          Cancel
        </el-button>
        <el-button
          id="submit-btn"
          type="primary"
          @click="submit"
        >
          Confirm
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
/**
 * SubscriptionForm
 *
 * Shared form for creating/editing product or feature subscriptions.
 *
 * Events:
 *
 * @close: triggers on cancel
 * @submit: triggers on form submit
 */
import { mapState } from 'vuex'
import { constants, enums } from '@/utils/constants'
import { validators } from '@/utils/element'
import {
  createBlankProductSubscription, createBlankFeatureSubscription, getStatus
} from '@/utils/rooof'
import { getTotalValue } from '@/utils/subscriptions'
import RecurringInvoiceDropdown from '@/components/forms/RecurringInvoiceDropdown'

export default {
  name: 'SubscriptionForm',
  components: { RecurringInvoiceDropdown },
  props: {
    subscription: {
      type: Object,
      default: null
    },
    subscriptionType: {
      type: String,
      required: true,
      validator: value => {
        return value === 'product' || value === 'feature'
      }
    },
    unitCount: {
      type: Number,
      default: undefined
    },
    showEndDate: {
      type: Boolean,
      default: false
    },
    premium: {
      type: Boolean,
      default: false
    }
  },
  data () {
    // Custom validator to check for product/feature selection
    const subscriptionTypeValidator = (rule, value, callback) => {
      if (!this.data[this.subscriptionType]) {
        return callback(new Error('This field is required'))
      }
      return callback()
    }
    // Custom validator for trial_end_date/end_date to ensure start is set
    const endDateValidator = (end, start, callback) => {
      if (end && !start) {
        return callback(new Error('This field requires a start date'))
      }
      return callback()
    }
    // Custom validator for discount
    const discountValidator = (rule, value, callback) => {
      if (!value) {
        return callback()
      }
      if (!this.data.rate) {
        return callback(new Error('Requires a rate'))
      }

      const rate = parseFloat(this.data.rate)
      const discount = parseFloat(value)

      if (this.data.discount_type === '$' && discount > rate) {
        return callback(new Error('Cannot exceed rate'))
      } else if (this.data.discount_type === '%' && discount > 100) {
        return callback(new Error('Value cannot exceed 100'))
      }
      return callback()
    }
    // Custom validator to ensure that unitCount is defined when low_density is selected
    const lowDensityValidator = (rule, value, callback) => {
      if (value && !this.unitCount) {
        return callback(new Error('This field requires a unit count'))
      }
      return callback()
    }
    // Custom validator to ensure that the reason_for_cancel is required when end_date is set
    const reasonForCancelValidator = (rule, value, callback) => {
      if (!value && this.data.end_date) {
        return callback(new Error('This field is required'))
      }
      return callback()
    }

    return {
      data: null,
      rules: {
        subscription_type: [
          { validator: subscriptionTypeValidator, trigger: 'blur' }
        ],
        rate: [
          { validator: validators.positiveNumber, trigger: 'blur' },
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        setup_fee: [
          { validator: validators.positiveNumber, trigger: 'blur' },
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        discount: [
          { validator: validators.positiveNumber, trigger: 'blur' },
          { validator: discountValidator, trigger: 'change' }
        ],
        trial_start_date: [
          { validator: validators.date, trigger: 'blur' }
        ],
        trial_end_date: [
          { validator: validators.date, trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              return endDateValidator(value, this.data.trial_start_date, callback)
            }
          },
          {
            validator: (rule, value, callback) => {
              return validators.billingDateValidator(value, this.data.trial_start_date, callback)
            }
          }
        ],
        start_date: [
          { validator: validators.date, trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!this.data.trial_start_date) {
                return callback()
              }
              return validators.billingDateValidator(value, this.data.trial_end_date, callback)
            }
          }
        ],
        end_date: [
          { validator: validators.date, trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              return endDateValidator(value, this.data.start_date, callback)
            }
          },
          {
            validator: (rule, value, callback) => {
              if (!this.data.trial_start_date) {
                return callback()
              }
              return validators.billingDateValidator(value, this.data.trial_end_date, callback)
            }
          }
        ],
        low_density: [
          { validator: lowDensityValidator, trigger: 'change' }
        ],
        reason_for_cancel: [
          { validator: reasonForCancelValidator, trigger: 'blur' }
        ],
        reason_for_cancel_other: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    currencyCode () {
      return this.$store.getters.getCompanyById(this.$route.params.cid).currency_code
    },
    isEdit () {
      return this.subscription !== null
    },
    /**
     * We want to disable and set the rate field for Price Updating when
     * the property is on Premium Full Service for Craigslist.
     */
    rateDisabled () {
      if (this.premium && this.subscriptionType === 'feature') {
        if (this.isEdit && this.subscription['feature'] === enums.features.PRICE_UPDATING) {
          return true
        }
        if (this.data && this.data.feature === enums.features.PRICE_UPDATING) {
          return true
        }
      }
      return false
    },
    subscriptionTypeLabel () {
      return this.subscriptionType.charAt(0).toUpperCase() + this.subscriptionType.slice(1)
    },
    subscriptionTypeOptions () {
      switch (this.subscriptionType) {
        case 'product':
          return this.products
        case 'feature':
          return this.features
        default:
          return []
      }
    },
    /**
     * We want to disable the end_date field when it is in the past
     * to discourage staff from reactivating existing inactive subscriptions.
     */
    disableEndDate () {
      if (this.data.status === enums.status.INACTIVE) {
        return this.$moment(this.data.end_date).endOf('day') < this.$moment(new Date())
      }
      return false
    },
    ...mapState({
      'products': state => state.products,
      'features': state => state.features
    })
  },
  watch: {
    subscription () {
      // Update data when source subscription changes
      this.data = this.getSubscriptionObject()
    }
  },
  created () {
    this.billingFrequencyOptions = constants.billingFrequency.filter(el => {
      return el.value !== null // remove 'Unknown'
    })
    const toRemove = ['subscription-change', 'switching-to-manual']
    this.reasonForCancel = constants.reasonForCancel.filter((el) =>
      !toRemove.includes(el.value)
    )
    this.data = this.getSubscriptionObject()
    this.getTotalValue = getTotalValue
  },
  methods: {
    /**
     * Create the object which can be passed into
     * element-ui's datepicker `pickerOptions` prop.
     *
     * Disables all dates up to and including the
     * provided date.
     *
     * @param {String|null} value - disable up to (and including) this date
     * @returns {Object}
     */
    getPickerOptions (value) {
      value = value !== null ? this.$moment(value).endOf('day') : value
      return {
        disabledDate: date => {
          return date <= value
        }
      }
    },
    /**
     * onChange handler for product/feature select.
     * Sets the subscription rate and setup fee according to the
     * default_rate and default_setup_fee.
     *
     * @param {String} value - selected value (name)
     */
    onSubscriptionSelect (value) {
      const obj = this.subscriptionTypeOptions.find(el => el.name === value)
      this.data.rate = this.rateDisabled ? '0' : obj.default_rate
      this.data.setup_fee = this.rateDisabled ? '0' : obj.default_setup_fee
      // Auto-clear validation
      this.$refs['subscription-form'].validateField('subscription_type')
    },
    /**
     * onClick handler for submit button.
     * Emits 'submit' event if form data is valid.
     */
    submit () {
      this.$refs['subscription-form'].validate(valid => {
        if (!valid) {
          return
        }
        // Add status field
        this.data.status = getStatus(
          this.data.trial_start_date,
          this.data.trial_end_date,
          this.data.start_date,
          this.data.end_date
        )
        this.$emit('submit', this.data)
        this.reset()
      })
    },
    /**
     * onClick handler for cancel button.
     * Resets form fields and emits 'cancel' event.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Reset form to default values.
     */
    reset () {
      this.$refs['subscription-form'].clearValidate()
      this.data = this.getSubscriptionObject()
    },
    /**
     * Creates a new subscription object.
     *
     * If we're editing an existing subscription, this
     * will be a copy of the passed in `subscription`
     * prop. If we're creating a new subscription, this
     * will be a completely new subscription object.
     *
     * @returns {Object}
     */
    getSubscriptionObject () {
      if (this.isEdit) {
        return JSON.parse(JSON.stringify(this.subscription))
      }
      switch (this.subscriptionType) {
        case 'product':
          return createBlankProductSubscription()
        case 'feature':
          return createBlankFeatureSubscription()
        default:
          return null
      }
    }
  }
}
</script>

<style>
#subscription-form .el-input-group__prepend {
  padding: 0 10px;
}
#subscription-form .el-input-group__append {
  padding: 0 10px;
}
</style>

<style scoped>
.row {
  display: flex;
}
.row.short {
  width: 480px;
}
.row.el-date-picker {
  width: 100px;
}
.small-input {
  min-width: 150px;
  max-width: 150px;
}
.col {
  margin-left: 20px;
}
.line {
  padding: 0 20px;
}
.form-controls {
  display: flex;
  justify-content: flex-end;
}
</style>
