<template>
  <div id="multi-field-update">
    <div
      v-for="(item, index) in data"
      :key="index"
    >
      <div
        v-if="index !== 0"
        class="and"
      >
        AND
      </div>
      <div class="row">
        <el-form-item label="Set">
          <el-select
            v-model="item.field"
            filterable
            @clear="handleSelect(index)"
            @change="handleSelect(index)"
          >
            <el-option
              v-for="field in fields"
              :key="field.value"
              :label="field.label"
              :value="field.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :prop="`data.${index}.value`"
          :rules="getValidationRules(index)"
          label="to"
          label-width="40px"
        >
          <component :is="renderInput(index)" />
        </el-form-item>

        <div class="add-remove-buttons">
          <i
            class="icon el-icon-circle-plus"
            @click="addItem(index + 1)"
          />
          <i
            v-if="index > 0"
            class="icon el-icon-remove"
            @click="removeItem(index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * MultiFieldUpdate
 *
 * Renders a list of updatable fields and their inputs.
 * Useful for batch updating a bunch of values.
 *
 * `data` prop should contain at least one item.
 * The default is: { field: null, value: null }
 *
 * You can pre-fill fields by setting `data` to
 * something other than null (`field` attr should
 * match one of the values in `fields`).
 */
export default {
  name: 'MultiFieldUpdate',
  props: {
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true,
      validator: value => {
        // Prop should not be empty
        if (value.length === 0) {
          return false
        }
        const fields = new Set()
        for (const config of value) {
          // Ensure required properties
          const hasRequired = ['label', 'value', 'render'].every(key => config.hasOwnProperty(key))
          if (!hasRequired) {
            return false
          }
          // Field names should be unique
          if (fields.has(config.value)) {
            return false
          }
          fields.add(config.value)
        }
        return true
      }
    }
  },
  methods: {
    /**
     * Dynamic components (:is attribute) accepts a string
     * (component name) or object (options object). We'd like to
     * be able to render a VNode because it gives us way more
     * flexibility in what the dynamic component looks like (for
     * example, we might want to add child components to a <select>
     * element).
     *
     * Each item in `fields` should define a render function config
     * which will determine the component to accept user input for
     * that field.
     *
     * See https://vuejs.org/v2/api/#render for more info.
     *
     * @param {Number} index
     * @returns {Object}
     */
    renderInput (index) {
      // If this component hasn't been configured to a field yet, set it
      // to the first item in `fields` list. The `value` property (field
      // name) will be used to find the config object.
      if (this.data[index].field === null) {
        const defaultField = this.fields[0]
        const defaultValue = defaultField.type ? defaultField.type() : ''
        this.$set(this.data[index], 'field', defaultField.value)
        this.$set(this.data[index], 'value', defaultValue)
      }

      const self = this

      return {
        name: 'ValueInput', // if name isn't defined we get an Anonymous Component
        render (h) {
          const config = self.getFieldConfig(index).render
          const options = config.options || {}

          // All inputs accept a `value` prop and emit the `input` event (v-model)
          if (options.props === undefined) {
            options.props = {}
          }
          if (options.on === undefined) {
            options.on = {}
          }
          options.props.value = self.data[index].value
          options.on.input = function (event) {
            self.$set(self.data[index], 'value', event)
          }

          let children = []

          // Add children (grandchildren not currently supported)
          if (config.children) {
            children = config.children.map(child => {
              return h(child.component, JSON.parse(JSON.stringify(child.options)))
            })
          }

          return h(config.component, options, children)
        }
      }
    },
    /**
     * Look up validation rules for the given field.
     *
     * @param {Number} index
     */
    getValidationRules (index) {
      const config = this.getFieldConfig(index)
      return config ? config.rules : null
    },
    /**
     * Inserts a new item to the list at index.
     *
     * @param {Number} index
     */
    addItem (index) {
      this.data.splice(index, 0, { field: null, value: null })
    },
    /**
     * Removes the item at the given index.
     *
     * @param {Number} index
     */
    removeItem (index) {
      this.data.splice(index, 1)
    },
    /**
     * onChange handler for field select input.
     *
     * When the field is changed, we want to reset the value
     * to a default. If `type` has been specified in the field
     * config that will be used, otherwise the empty string.
     *
     * @param {Number} index
     */
    handleSelect (index) {
      const config = this.getFieldConfig(index)
      const value = config.type ? config.type() : ''
      this.$set(this.data[index], 'value', value)
    },
    /**
     * Get the `fields` config object for a given index.
     *
     * @param {Number} index
     * @returns {Object}
     */
    getFieldConfig (index) {
      return this.fields.find(field => field.value === this.data[index].field)
    }
  }
}
</script>

<style lang="scss">
#multi-field-update {
  .el-input {
    width: 220px;
  }
}
</style>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 0;
}
.row {
  display: flex;
  align-items: flex-start;

  .add-remove-buttons {
    align-self: center;
    margin-left: 8px;
    min-width: 50px;
    width: 50px;

    .icon {
      cursor: pointer;
      font-size: 18px;
    }

    .el-icon-circle-plus {
      color: #2f6eae;
      margin-right: 5px;
    }

    .el-icon-remove {
      color: #f56c6c;
    }
  }
}
.and {
  text-align: center;
  font-weight: bold;
  margin: 10px 0 10px 6px;
}
</style>
