<template>
  <div id="form-dialog">
    <el-dialog
      :visible="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="showClose"
      :title="title"
      :width="width"
      @open="$emit('open')"
      @close="$emit('close')"
    >
      <slot />
    </el-dialog>
  </div>
</template>

<script>
/**
 * FormDialog
 *
 * Generic component to wrap a form in a dialog.
 */
export default {
  name: 'FormDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '500px'
    },
    showClose: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
#form-dialog .el-dialog__body {
  padding: 20px;
}
</style>
